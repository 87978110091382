<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Are you sure?
        </v-card-title>
        <v-card-text>Data cannot back if you delete.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="red darken-1"
              text
              @click="dialog = false"
          >
            Disagree
          </v-btn>
          <v-btn
              color="green darken-1"
              text
              @click="agreeBtn"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "DialogConfirm",
  data () {
    return {
      dialog: false,
      rev: 0,
      eventAction: '',
    }
  },
  created() {
    this.action.$on('open-dialog-conf-user', (result) => {
      this.dialog = result;
    });

    this.action.$on('open-dialog-conf-merchant', (result) => {
      this.dialog = result;
      this.eventAction = 'merchant';
    });

    this.action.$on('open-dialog-conf-bank', (result) => {
      this.dialog = result;
      this.eventAction = 'bank';
    });

    this.action.$on('open-dialog-conf-category', (result) => {
      this.dialog = result;
      this.eventAction = 'category';
    });

    this.action.$on('open-dialog-conf-product', (result) => {
      this.dialog = result;
      this.eventAction = 'product';
    });

    this.action.$on('open-dialog-rev', (result) => {
      this.rev = 1;
      this.dialog = result;
    });
  },
  beforeDestroy() {
    // this.action.$off('open-dialog-conf-user');
    this.action.$off('delete-data-user');
    this.action.$off('delete-data-merchant');
    this.action.$off('delete-data-bank');
    this.action.$off('delete-data-category');
    this.action.$off('delete-data-product');
  },
  methods : {
    agreeBtn() {
      if (this.rev === 1) {
        this.dialog = false;
        this.action.$emit('delete-data-rev');
      } else if (this.eventAction === 'merchant') {
        this.dialog = false;
        this.action.$emit('delete-data-merchant');
      } else if (this.eventAction === 'bank') {
        this.dialog = false;
        this.action.$emit('delete-data-bank');
      } else if (this.eventAction === 'category') {
        this.dialog = false;
        this.action.$emit('delete-data-category');
      } else if (this.eventAction === 'product') {
        this.dialog = false;
        this.action.$emit('delete-data-product');
      } else {
        this.dialog = false;
        this.action.$emit('delete-data-user');
      }
    }
  }
}
</script>

<style scoped>

</style>
